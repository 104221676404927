// React dependencies
import "./App.scss";
import { Routes, Route, BrowserRouter } from "react-router-dom";

// Components
import Footer from "./components/Footer/Footer";

// Pages
import CookiesPage from "./pages/Cookies";
import Home from "./pages/Home";
import Error from "./pages/Error";

// App.tsx 

const App = (): JSX.Element => {

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route index element={<Home />}></Route>
          <Route path="/privacy-policy" element={<CookiesPage />} />
          <Route path="/*" element={<Error />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
};

export default App;
       