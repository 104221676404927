import { Link } from "react-router-dom";

const Navbar = (): JSX.Element => {
  
  return (
    <>
    <ul className="l-footer-links">
      <li><Link className="c-link-secondary" to="/privacy-policy">Privacy and cookies policy</Link></li>
      <li><Link className="c-link-secondary" to="/">Home</Link></li>
    </ul>
    </>
  )
}

export default Navbar
