const countriesUSA = [
  {
    id: 18,
    name: "USA",
    languages: [
      {
        nlang: "English (US)",
        url: "https://www.socomec.us/en-us",
      },
    ],
  },
];

export default countriesUSA;
