import "./footer.scss";
import { ReactComponent as SvgFacebook } from '../../svg/facebook.svg';
import { ReactComponent as SvgLinkedin } from '../../svg/linkedin.svg';
import { ReactComponent as SvgTwitter } from '../../svg/twitter.svg';
import { ReactComponent as SvgYoutube } from '../../svg/youtube.svg';
import Navbar from '../Navbar/Navbar';



const Footer = (): JSX.Element => {
  
  return (
    <>
      <footer className="l-footer">
            <div className="l-footer-top">
              <div className="o-container">
                <div className="l-footer-content logo-footer">
                  <img src="assets/images/logo-blue.png" alt="socomec logo" />
                </div>
                <div className="l-footer-content social">
                  {/*<a href="https://www.facebook.com/SocomecGroup/" target="_blank" rel="noreferrer" ><SvgFacebook/></a>*/}
                  <a href="https://www.linkedin.com/company/socomec/t" target="_blank" rel="noreferrer" ><SvgLinkedin/></a>
                  {/*<a href="https://twitter.com/socomec_group/" target="_blank" rel="noreferrer" ><SvgTwitter/></a>*/}
                  <a href="https://www.youtube.com/c/SocomecGroup" target="_blank" rel="noreferrer" ><SvgYoutube/></a>
                </div>
              </div>
            </div>
            <div className="l-footer-bottom">
              <div className="o-container">
                <Navbar/>
                <div className="l-footer-copyright">
                  <p>Copyright © 2023. All rights reserved</p>
                </div>
              </div>
            </div>
        </footer>
    </>
  )
}

export default Footer
