import countriesEU from "../data/countries-eu";
import countriesAS from "../data/countries-as";
import countriesUSA from "../data/countries-usa";

import { Link } from "react-router-dom";

interface Country {
  id: number;
  name: string;
  languages: {
    nlang: string;
    url: string;
  }[];
}

function sortCountriesByProperty(countries: Country[], property: keyof Country) {
  return countries.sort((a, b) => {
    if (a[property] === "Others countries in this area") {
      return 1;
    }
    if (b[property] === "Others countries in this area") {
      return -1;
    }
    if (a[property] < b[property]) {
      return -1;
    }
    if (a[property] > b[property]) {
      return 1;
    }
    return 0;
  });
}

const sortedCountriesAS = sortCountriesByProperty(countriesAS, "name");
const sortedCountriesEU = sortCountriesByProperty(countriesEU, "name");
const sortedCountriesUSA = sortCountriesByProperty(countriesUSA, "name");

const App = (): JSX.Element => {
  return (
    <>
      <div className="l-hero-wallpaper">
        <img src="assets/images/BG@2x.jpg" alt="socomec illustration" />
      </div>
      <header className="o-header">
        <div className="o-container">
          <div className="logo-wrapper">
            <Link to="/">
              <img src="assets/images/logo.png" alt="socomec logo" />
            </Link>
          </div>
          <h2 className="h2-like">
            Select your country to access to your regional website
          </h2>
        </div>
      </header>
      <div className="o-container">
        <div className="o-wrapper">
          <div className="c-country-wrapper">
            <h3 className="h2-like h2-like--tile">
              Europe, Middle East & Africa
            </h3>
            <div className="o-grid--twocol">
              {sortedCountriesEU.map((countryEU, index) => (
                <div className="c-country" key={index}>
                  <div className="left">
                    <p className="c-cname">{countryEU.name}</p>
                  </div>
                  <div className="right">
                    <ul className="c-lang">
                      {countryEU.languages.map((lang, i) => (
                        <li key={i}>
                          <a className="c-link" href={lang.url}>
                            {lang.nlang}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="c-country-wrapper">
            <h3 className="h2-like h2-like--tile">Asia & Pacific</h3>
            <div className="o-grid--twocol">
              {sortedCountriesAS.map((countryAS, index) => (
                <div className="c-country" key={index}>
                  <div className="left">
                    <p className="c-cname">{countryAS.name}</p>
                  </div>
                  <div className="right">
                    <ul className="c-lang">
                      {countryAS.languages.map((lang, i) => (
                        <li key={i}>
                          <a className="c-link" href={lang.url}>
                            {lang.nlang}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="c-country-wrapper">
            <h3 className="h2-like h2-like--tile">America</h3>
            <div className="o-grid--twocol">
              {sortedCountriesUSA.map((countryUSA, index) => (
                <div className="c-country" key={index}>
                  <div className="left">
                    <p className="c-cname">{countryUSA.name}</p>
                  </div>
                  <div className="right">
                    <ul className="c-lang">
                      {countryUSA.languages.map((lang, i) => (
                        <li key={i}>
                          <a className="c-link" href={lang.url}>
                            {lang.nlang}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default App;
