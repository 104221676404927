const countriesAS = [
  {
    id: 15,
    name: "India",
    languages: [
      {
        nlang: "English (IN)",
        url: "https://www.socomec.co.in/en-in",
      },
    ],
  },
  {
    id: 16,
    name: "China",
    languages: [
      {
        nlang: "Chinese",
        url: "https://www.socomec.cn/",
      },
    ],
  },
  {
    id: 17,
    name: "Others countries in this area",
    languages: [
      {
        nlang: "English",
        url: "https://apac.socomec.com/en",
      },
    ],
  },
];

export default countriesAS;
