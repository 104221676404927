import { Link } from "react-router-dom";

const Cookies = (): JSX.Element => {
  return (
    <>
      <div className="l-hero-wallpaper">
        <img
          src="assets/images/BG-international.jpg"
          alt="socomec illustration"
        />
      </div>
      <header className="o-header">
        <div className="o-container">
          <div className="logo-wrapper">
            <Link to="/">
              <img src="assets/images/logo.png" alt="socomec logo" />
            </Link>
          </div>
          <h2 className="h2-like">Privacy policy</h2>
        </div>
      </header>
      <div className="o-container">
        <div className="o-wrapper o-content">
          <div className="clearfix text-formatted field field--name-field-text field--type-text-long field--label-hidden field__item">
            <p className="c-yellow">
              <strong>Effective date: 01.06.2022</strong>
            </p>

            <p>&nbsp;</p>

            <h3 className="custom-cke-subtitle-3">1. Who are we?</h3>

            <p>
              SOCOMEC (hereinafter "<strong>Socomec</strong>" or "
              <strong>we</strong>") is a SAS registered in the Strasbourg Trade
              and Companies Register under the number 548 500 149 and whose
              registered office is located at 1 rue de Westhouse, 67230 Benfeld
              - France.
            </p>

            <p>
              The purpose of this policy is to inform you of the principles
              governing the way in which we collect, use and store your personal
              data in your capacity as a Socomec customer, as well as the main
              purposes of the processing carried out. It may be supplemented by
              various other information documents depending on the
              product/service that Socomec provides you.
            </p>

            <p>
              We invite you to read this policy carefully and to consult it
              regularly in order to take note of any changes or updates.
            </p>

            <p>&nbsp;</p>

            <h3 className="custom-cke-subtitle-3">
              2. What is the applicable legal and regulatory framework?
            </h3>

            <p>
              Socomec, in its capacity as data controller, is required to
              process some of your personal data (i.e. any information about you
              that makes you directly or indirectly personally identifiable) in
              accordance with the applicable regulations on the protection of
              personal data ("
              <strong>
                Applicable regulations on the protection of personal data
              </strong>
              ").
            </p>

            <p>
              The latter includes, in particular, (i) the law relating to data
              processing, files and freedoms no. 78-17 of 6 January 1978 as
              amended and any updates thereto, (ii) Regulation (EU) 2016/679 of
              the European Parliament and of the Council of 27 April 2016
              (hereinafter the "<strong>GDPR</strong>"), and (iii) where
              applicable, texts adopted within the European Union and local laws
              that may apply to personal data processed within the framework of
              our relationship.
            </p>

            <p>&nbsp;</p>

            <h3 className="custom-cke-subtitle-3">
              3. How is your personal data processed?
            </h3>

            <h4 className="custom-cke-subtitle-4">
              3.1. Purposes and lawfulness of processing
            </h4>

            <p>
              The personal data we collect is used for the following purposes,
              on the legal basis associated with them:
            </p>

            <p>
              Processing relating to the commercial relationship (excluding
              prospecting/loyalty activities):
            </p>


          <table className="table" role="grid">
            <thead>
              <tr role="row">
                <th className="th-center" scope="col">Purpose of processing</th>
                <th className="th-center" scope="col">Legal basis</th>
                </tr>
                </thead>
                <tbody>
                  <tr role="row" className="odd">
                    <td>Management, qualification and response to requests (e.g. from the website, by telephone, e-mail or from Socomec applications), in particular:<br/>
                      - Contact requests;<br/>
                      - Requests for quotations;<br/>
                      - Provision of technical support, etc., including from the website
                    </td>
                    <td>Execution of pre-contractual measures or our legitimate interest in responding to requests</td>
                  </tr>
                  <tr role="row" className="even">
                    <td>Management of orders, deliveries and after-sales service</td>
                    <td>Performance of a contract</td>
                  </tr>
                    <tr role="row" className="odd"><td>Management of commercial and legal warranties</td>
                      <td>Performance of a contract / legal obligation depending on the warranty in question</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>Management of possible pre-litigation and litigation (including collection of outstanding debts)</td>
                      <td>Legitimate interest / Performance of a contract</td>
                    </tr>
                      <tr role="row" className="odd">
                        <td>Management of accounting and tax obligations</td>
                        <td>Legal obligation</td>
                    </tr>
                    <tr role="row" className="even">
                      <td>Management of customer/prospect knowledge and subsequent analysis, including:<br/>
                        - Scoring;<br/>
                        - Financial solvency assessment and profiling;<br/>
                        - Satisfaction surveys;<br/>
                        - Quality surveys
                      </td>
                    <td>Performance of a contract / legitimate interest</td>
                  </tr>
                </tbody>
            </table>
            <p>Processing related to business development/prospecting activities:</p>

            <table className="table">
              <thead>
                <tr>
                  <th className="th-center" scope="col">Purpose of processing</th>
                  <th className="th-center" scope="col">Legal basis</th>
                </tr>
              </thead>
              <tbody>
                <tr role="row">
                  <td >Prospecting by electronic means, including for similar goods and services, such as:<br/>
                    - Sending of newsletter e-mails;<br/>
                    - Sending of commercial communications
                  </td>
                  <td rowSpan={2}>Consent / legitimate interest in communicating with our prospects/customers as appropriate</td>
                </tr>
                <tr>
                  <td >Prospecting by post (e.g. sending catalogues)</td>
                </tr>
                <tr>
                  <td >Management of the opposition list</td>
                  <td >Legal obligation</td>
                </tr>
                <tr>
                  <td >Organisation/participation in customer development and loyalty events:<br/>
                  - Digital events (e.g. webinars);<br/>
                  - Physical events (e.g. factory visits, trade fairs, DemoVan, competitions, etc.)
                  </td>
                  <td >Legitimate interest</td>
                </tr>
                  <tr>
                    <td >Provision of tools related to Socomec products (e.g. selectors, configurators, catalogues, etc.)</td>
                    <td >Performance of a contract / legitimate interest</td>
                </tr>
              </tbody>
            </table>

            <p>Other processing related to the management of our website and social networks:</p>

            <table className="table">
              <thead>
                <tr>
                  <th className="th-center" scope="col">Purpose of processing</th>
                  <th className="th-center" scope="col">Legal basis</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Management of our e-reputation</td>
                  <td>Legitimate interest</td>
                </tr>
                <tr>
                  <td>Administration of our website and its security</td>
                  <td rowSpan={3}>Performance of a contract / legitimate interest</td>
                </tr>
                  <tr><td>Establishment of usage statistics</td>
                </tr>
                <tr>
                  <td>Management of user accounts</td>
                </tr>
                <tr>
                  <td>Management of social network accounts</td>
                  <td rowSpan={2}>Legitimate interest</td>
                </tr>
                <tr>
                  <td>Management of interactions with people who come into contact with Socomec accounts</td>
                </tr>
              </tbody>
            </table>

						

            <p>
              You can contact us at the address mentioned below for any
              clarification regarding the processing of your personal data.
            </p>

            <p>
              We would like to draw your attention to the fact that certain
              processing operations, in particular those related to customer
              knowledge and prospecting, lead us to build up profiles of our
              customer-types ("profiling") by analysing your preferences, in
              particular in order to send personalised communications.
            </p>

            <p>
              Furthermore, you are aware that your personal data may also be
              processed due to new obligations (in particular legal
              obligations), or to defend the interests of Socomec or a third
              party. We will inform you in the event of new processing on these
              bases.
            </p>

            <p>&nbsp;</p>

            <h4 className="custom-cke-subtitle-4">
              3.2. Categories of personal data
            </h4>

            <p>
              Within the framework of our relationship, Socomec is required to
              process, in particular, the following categories of data,
              depending on the processing that is actually carried out on you:
            </p>

            <table className="table-horizontal">
              <tbody>
                <tr>
                  <th scope="row">Identity data</th>
                  <td className="text-align-center">
                    Name, first name, e-mail address, telephone number, postal
                    address
                  </td>
                </tr>
                <tr>
                  <th scope="row">Professional data</th>
                  <td className="text-align-center">
                    Position, company name, LinkedIn account data, SIREN number
                  </td>
                </tr>
                <tr>
                  <th scope="row">Commercial data</th>
                  <td className="text-align-center">
                    Information related to requests (contact, documentation,
                    etc.), purchases of products/services made,
                    quantity/amount/date of orders and related invoices,
                    delivery address, history of purchases and possible returns,
                    all correspondence and/or exchanges with the
                    customer/prospect including after-sales service or on social
                    networks (including data made accessible by the latter,
                    messages exchanged or publications)
                  </td>
                </tr>
                <tr>
                  <th scope="row">Payment data</th>
                  <td className="text-align-center">
                    Payments made, their terms and conditions, bank details,
                    cheque and CB numbers (including card validity and
                    cryptogram)
                  </td>
                </tr>
                <tr>
                  <th scope="row">Connection, activity and behavioural data</th>
                  <td className="text-align-center">
                    Sites visited, IP address, logs, identifiers, dates and
                    times of connection/disconnection, location, data collected
                    by cookies deposited.
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              We collect this information directly from you, from your employer,
              from our business partners, from our distributors or subsidiaries,
              and from database renters, depending on the purpose.
            </p>

            <p>&nbsp;</p>

            <h3 className="custom-cke-subtitle-3">
              4. How long will your personal data be kept?
            </h3>

            <p>
              The personal data we collect is processed for no longer than is
              necessary for the purposes for which it was collected, taking into
              account the applicable limitation periods and the principle of
              proportionality.
            </p>

            <ul>
              <li>
                <u>Concerning data relating to customer management:</u> personal
                data relating to customers is not kept beyond the period
                strictly necessary for the management of the commercial
                relationship, extended by intermediate archiving for a legal
                period.
              </li>
              <li>
                <u>Concerning data relating to the management of prospects:</u>{" "}
                customer data used for commercial prospecting purposes may be
                kept for a period of 3 years from the end of the commercial
                relationship. Personal data relating to a non-customer prospect
                may be kept for a period of 3 years from the date of collection
                or the last contact from the prospect.
                <br />
                At the end of this 3-year period, Socomec may contact the person
                concerned again to find out if he/she wish to continue receiving
                commercial solicitations. In the absence of a positive and
                explicit response from the person, the data will be deleted or
                archived in accordance with the provisions in force.
              </li>
              <li>
                <u>Concerning connection data:</u> We keep this data for a
                maximum of 1 year, except in cases where specific legal and/or
                regulatory provisions impose a different retention period.
              </li>
            </ul>
            <p>
              For more information on the retention periods we apply, you can
              contact us at the address below.
            </p>

            <p>&nbsp;</p>

            <h3 className="custom-cke-subtitle-3">
              5. To whom is your personal data disclosed?
            </h3>

            <p>
              Your personal data is passed on to our internal departments
              involved in the above-mentioned purposes, in particular to the
              sales and change management, marketing and communication, IT,
              legal or finance and accounting departments.
            </p>

            <p>
              It is also communicated to the following recipients, strictly in
              consideration of the purposes sought:
            </p>

            <ul>
              <li>
                Our commercial partners and distributors, including our
                subsidiaries;
              </li>
              <li>
                Our service providers (in particular for IT, marketing and
                communication, including software);
              </li>
              <li>Our advisers (legal or otherwise);</li>
              <li>
                The competent authorities in the event of infringement and/or
                litigation.
              </li>
            </ul>
            <p>
              Please note that where we use subcontractors within the meaning of
              the Applicable regulations on the protection of personal data,
              they are obliged to ensure the security and confidentiality of the
              personal data provided to them and must only use such data on our
              instructions and in accordance with the data processing agreement
              they have signed with us.
            </p>

            <p>&nbsp;</p>

            <h3 className="custom-cke-subtitle-3">
              6. How do we protect your personal data?
            </h3>

            <p>
              We are committed to ensuring the confidentiality, integrity,
              availability and security of your personal data.
            </p>

            <p>
              In accordance with Article 32 of the GDPR, we endeavour to
              implement all necessary and appropriate technical, logical and
              organisational measures to ensure the level of security best
              suited to the risks involved in processing such personal data.
            </p>

            <p>
              We also put in place means to prevent, as far as possible, any
              loss, accidental destruction, alteration and unauthorised access
              to this personal data.
            </p>

            <p>
              These measures include storing your personal data in a secure
              operating environment, accessible only to authorised persons who
              are required to respect the confidentiality of your data.
            </p>

            <p>&nbsp;</p>

            <h3 className="custom-cke-subtitle-3">
              7. Is your data likely to be transferred outside the European
              Union?
            </h3>

            <p>
              Yes – some of our subcontractors are located outside the European
              Union, and some of our tools are accessible by Socomec Group
              employees who are also located outside the European Union (in
              particular in the United States, Latin America or Asia).
            </p>

            <p>
              In this case, and in order to guarantee a sufficient level of
              protection for your personal data, we have put in place tools to
              secure these transfers.
            </p>

            <p>&nbsp;</p>

            <h3 className="custom-cke-subtitle-3">
              8. Will our policy change in the future?
            </h3>

            <p>
              Yes – we reserve the right to change our privacy policy at any
              time in accordance with the Applicable regulations on the
              protection of personal data.
            </p>

            <p>
              Any changes shall apply immediately and will be indicated at the
              beginning of the privacy policy with the relevant effective date.
            </p>

            <p>
              We invite you to regularly consult the latest version in force,
              accessible from the footer of our website:{" "}
              <a href="https://www.socomec.com/">www.socomec.com</a>.
            </p>

            <p>&nbsp;</p>

            <h3 className="custom-cke-subtitle-3">
              9. What rights do you have?
            </h3>

            <p>
              In accordance with the applicable law, you have the following
              rights, depending on the processing operation in question:
            </p>

            <p>&nbsp;</p>

            <h4 className="custom-cke-subtitle-4">
              9.1. Rights to information, access and portability
            </h4>

            <p>
              You have the right to access your personal data processed by us
              and to know why we process your data, where we get it from and who
              receives it.
            </p>

            <p>
              We will provide you with the requested information, in writing or
              electronically, within a reasonable time and at the latest within
              one month, provided that your request is not excessive.
            </p>

            <p>
              You also have the right to obtain a copy of the personal data we
              process about you. If you have submitted your request
              electronically and do not request another method of communication,
              this information will be provided to you in electronic form.
            </p>

            <p>&nbsp;</p>

            <h4 className="custom-cke-subtitle-4">
              9.2. Right of rectification
            </h4>

            <p>
              You may request that your personal data be updated, corrected
              and/or completed at any time.
            </p>

            <p>
              Depending on the circumstances, you may also be able to rectify
              your information yourself.
            </p>

            <p>&nbsp;</p>

            <h4 className="custom-cke-subtitle-4">9.3. Right to erasure</h4>

            <p>
              You have the right to obtain the deletion of your personal data
              for legitimate reasons. Nevertheless, it is brought to your
              attention that, due to the nature of certain processing operations
              and, in particular, their legal basis (e.g. to meet a legal
              obligation), we will only be able to erase your personal data once
              the legal retention obligation and/or the applicable limitation
              periods have expired.
            </p>

            <p>&nbsp;</p>

            <h4 className="custom-cke-subtitle-4">
              9.4. Right to limit processing
            </h4>

            <p>
              You may ask us to temporarily suspend the use of some of your
              personal data, in particular if you dispute the accuracy of all or
              part of the data.
            </p>

            <p>&nbsp;</p>

            <h4 className="custom-cke-subtitle-4">9.5. Right to object</h4>

            <p>
              You may object to the processing of your personal data, including
              profiling based on these provisions. The processing must then
              cease, unless it is necessary, for example, to comply with social
              and tax legislation, to defend our interests or those of a third
              party or to establish, exercise or defend legal claims.
            </p>

            <p>&nbsp;</p>

            <h4 className="custom-cke-subtitle-4">
              9.6. Right to withdraw your consent
            </h4>

            <p>
              You may withdraw your consent at any time, provided that we have
              previously required your consent for the processing in question.
            </p>

            <p>
              You acknowledge and agree that the withdrawal of such consent does
              not affect the legitimacy of the processing that was carried out
              on the basis of the consent you gave prior to the withdrawal.
            </p>

            <p>&nbsp;</p>

            <h4 className="custom-cke-subtitle-4">
              9.7. Right to information in case of data breach
            </h4>

            <p>
              In the event of a personal data breach within Socomec, we will
              provide you with information about such a breach, if it is likely
              to result in a high risk to your rights and freedoms.
            </p>

            <p>
              You acknowledge and agree that such communication is not necessary
              when Socomec has implemented appropriate measures to reduce or
              eliminate the high risk of infringement to your rights and
              freedoms.
            </p>

            <p>&nbsp;</p>

            <h4 className="custom-cke-subtitle-4">
              9.8. Right to lodge a complaint with the supervisory authority
            </h4>

            <p>
              You also have the right to lodge a complaint with the Commission
              Nationale de l'Informatique et des Libertés (CNIL), regarding the
              way in which we process your personal data, in particular from its
              website{" "}
              <a href="https://www.cnil.fr" target="_blank" rel="noreferrer">
                www.cnil.fr
              </a>
              .
            </p>

            <p>&nbsp;</p>

            <h3 className="custom-cke-subtitle-3">10. How to contact us?</h3>

            <p>
              If you have any questions about this privacy policy or any request
              relating to the processing of your personal data (including the
              exercise of your rights), you can contact our Data Protection
              Officer (DPO):
            </p>

            <ul>
              <li>
                By post at the following address: EDOS STRASBOURG, To the
                attention of the SOCOMEC’s DPO, 9 rue Schimper 67200
                STRASBOURG (France)
              </li>
              <li>
                By e-mail to the following address: dataprivacy@socomec.com
              </li>
            </ul>
            <p>
              If you wish to exercise your rights, we recommend that you provide
              us with any information that will enable you to prove your
              identity (e.g. your customer number together with your identity
              and address, or even an identity card). If we have any doubts
              about your identity, we may ask you to provide us with other
              information enabling us to verify your identity (national identity
              card, passport, driving licence, etc.), in order to avoid your
              data being communicated to persons other than you. This
              information will be deleted once the verification has been
              completed.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Cookies;
