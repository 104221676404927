const countriesEU = [
  {
    id: 1,
    name: "Belgium",
    languages: [
      {
        nlang: "French",
        url: "https://www.socomec.be/fr",
      },
      {
        nlang: "Dutch",
        url: "https://www.socomec.be/nl",
      },
    ],
  },
  {
    id: 2,
    name: "Portugal",
    languages: [
      {
        nlang: "Portuguese",
        url: "https://www.socomec.pt/pt",
      },
    ],
  },
  {
    id: 3,
    name: "Romania",
    languages: [
      {
        nlang: "Romanian",
        url: "https://www.socomec.ro/ro",
      },
    ],
  },
  {
    id: 4,
    name: "France",
    languages: [
      {
        nlang: "French",
        url: "https://www.socomec.fr/fr",
      },
    ],
  },
  {
    id: 5,
    name: "Slovenia",
    languages: [
      {
        nlang: "Slovenian",
        url: "https://www.socomec.si/sl",
      },
    ],
  },
  {
    id: 6,
    name: "Germany",
    languages: [
      {
        nlang: "German",
        url: "https://www.socomec.de/de",
      },
    ],
  },
  {
    id: 7,
    name: "Spain",
    languages: [
      {
        nlang: "Spanish",
        url: "https://www.socomec.es/es",
      },
    ],
  },
  {
    id: 8,
    name: "Italy",
    languages: [
      {
        nlang: "Italian",
        url: "https://www.socomec.it/it",
      },
    ],
  },
  // {
  //   id: 9,
  //   name: "Switzerland",
  //   languages: [
  //     {
  //       nlang: "Deutsch",
  //       url: "https://www.socomec.ch/home_de.html",
  //     },
  //     {
  //       nlang: "French",
  //       url: "https://www.socomec.ch/Accueil_fr.html",
  //     },
  //     {
  //       nlang: "Italian",
  //       url: "",
  //     },
  //   ],
  // },
  {
    id: 10,
    name: "Netherlands",
    languages: [
      {
        nlang: "Dutch",
        url: "https://www.socomec.nl/nl",
      },
    ],
  },
  {
    id: 11,
    name: "Turkey",
    languages: [
      {
        nlang: "Turkish",
        url: "https://www.socomec.com.tr/tr",
      },
    ],
  },
  {
    id: 12,
    name: "Poland",
    languages: [
      {
        nlang: "Polish",
        url: "https://www.socomec.pl/pl",
      },
    ],
  },
  {
    id: 13,
    name: "United Kingdom",
    languages: [
      {
        nlang: "English (GB)",
        url: "https://www.socomec.co.uk/en-gb",
      },
    ],
  },
  {
    id: 14,
    name: "Sweden",
    languages: [
      {
        nlang: "Swedish",
        url: "https://megacon.se",
      },
    ],
  },
  {
    id: 15,
    name: "Switzerland",
    languages: [
      {
        nlang: "German",
        url: "https://www.socomec.ch/de",
      },
      {
        nlang: "French",
        url: "https://www.socomec.ch/fr",
      },
      {
        nlang: "Italian",
        url: "https://www.socomec.ch/it",
      },
    ]
  },
  {
    id: 16,
    name: "Russia",
    languages: [
      {
        nlang: "Russian",
        url: "https://www.socomec.ru/ru",
      },
    ]
  },
  {
    id: 17,
    name: "Others countries in this area",
    languages: [
      {
        nlang: "English",
        url: "http://emea.socomec.com/en",
      },
      {
        nlang: "French",
        url: "http://emea.socomec.com/fr",
      },
    ],
  },

];

export default countriesEU;
