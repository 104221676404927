
import { Link } from 'react-router-dom'
const Error = (): JSX.Element => {
  
  return (
    <>
      <div className="l-hero-wallpaper">
        <img
          src="assets/images/BG-international.jpg"
          alt="socomec illustration"
        />
      </div>
      <header className="o-header">
        <div className="o-container">
          <div className="logo-wrapper">
            <Link to="/">
              <img src="assets/images/logo.png" alt="socomec logo" />
            </Link>
          </div>
          <h2 className="h2-like">
            404
          </h2>
        </div>
      </header>
      <div className="o-container">
        <div className="o-wrapper">
          <div className="o-content message">
            <h3 className="h3-like">404. Page not found</h3>
            <Link className="btn-primary" to="/"> go back to home </Link>
          </div>
        </div>
      </div>
    </>
  )
}

export default Error
